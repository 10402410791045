import config from "../../contracts.config.json";
import { store } from "@/store";

/**
 * @notice Parses a fxToken from alias to address.
 * @param {string} fxToken
 * @returns {string}
 */
export default (fxToken) => {
  if (fxToken.startsWith("fx") || fxToken.length < 40) {
    // Use loaded network name so that fetching wallet provider is not needed,
    // allowing this to be used from unit tests.
    const addresses = config[store.state.network || "homestead"];
    fxToken =
      addresses?.fxTokens?.[fxToken] ??
      addresses?.collaterals?.[fxToken] ??
      addresses?.thirdPartyContracts?.[fxToken] ??
      addresses?.[fxToken];
    fxToken = fxToken?.toLowerCase();
  }
  return fxToken;
};
