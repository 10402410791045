var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uk-margin"},[(!_vm.hideLabel)?_c('label',{staticClass:"uk-form-label hfi-form-label-width-medium",attrs:{"for":_vm.id === '' ? 'selectFxToken' : _vm.id}},[_vm._v(" "+_vm._s(_vm.label ? _vm.label : "fxToken")+" ")]):_vm._e(),_c('div',{staticClass:"uk-form-controls"},[_c('Select',{class:_vm.boundaryClass,attrs:{"id":_vm.id === '' ? 'selectFxToken' : _vm.id,"boundaryClass":_vm.boundaryClass,"selectClasses":"uk-width-expand","inputClasses":"uk-text-left","options":_vm.options || _vm.supportedTokens,"icons":[
        'fxAUDLogo.png',
        'fxPHPLogo.png',
        'fxCNYLogo.png',
        'fxEURLogo.png',
        'fxJPYLogo.png',
        'fxKRWLogo.png',
        'fxSGDLogo.png',
        'fxGBPLogo.png',
        'fxUSDLogo.png',
        'fxCHFLogo.png' ],"value":_vm.value ? _vm.value : _vm.Token.fxAUD,"disabled":_vm.disabled},on:{"change":_vm.setFxToken}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }