<template>
  <div class="uk-margin">
    <label
      class="uk-form-label hfi-form-label-width-medium"
      for="select-collateral"
    >
      {{ label ? label : "collateral" }}
    </label>
    <div class="uk-form-controls">
      <Select
        :id="id === '' ? 'select-collateral' : id"
        :class="id === '' ? 'select-collateral' : id + '-boundary'"
        :boundaryClass="id === '' ? 'select-collateral' : id + '-boundary'"
        selectClasses="uk-width-expand"
        inputClasses="uk-text-left"
        :options="collateralTokens"
        :value="value ? value : Token.ETH"
        @change="setCollateralToken"
        :disabled="disabled"
        :showETHForWETH="showETHForWETH"
        :unavailable="unavailable"
        :useTokenList="true"
      />
    </div>
  </div>
</template>

<script>
import Select from "@/components/Select";
import Token from "@/types/Token";
import CollateralTokens from "@/types/CollateralTokens";

const collateralTokensFiltered = CollateralTokens.filter(
  (t) => t !== Token.FOREX
);

export default {
  name: "SelectCollateralToken",
  comments: [Select],
  props: {
    id: { type: String, default: "" },
    value: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: "" },
    options: { type: Array },
    unavailable: { type: Array },
    showETHForWETH: { type: Boolean, default: false },
  },
  data() {
    return {
      Token,
      collateralTokens: collateralTokensFiltered,
    };
  },
  watch: {
    options() {
      this.collateralTokens =
        this.options?.length > 0 ? this.options : collateralTokensFiltered;
    },
  },
  mounted() {
    this.collateralTokens =
      this.options?.length > 0 ? this.options : collateralTokensFiltered;
  },
  updated() {
    this.collateralTokens =
      this.options?.length > 0 ? this.options : collateralTokensFiltered;
  },
  methods: {
    setCollateralToken(value) {
      const newValue = value;
      this.$emit("change", newValue);
    },
  },
};
</script>
