<template>
  <div>
    <div class="uk-form-controls" style="position: relative">
      <div
        ref="ratio-wrapper"
        :class="`collateral-ratio ${isReadOnly ? 'disabled-opacity' : ''}`"
      >
        <div ref="ratio-bar" class="bar">
          <div class="red" :style="{ flex: redFlexSize() }"></div>
          <div class="orange" :style="{ flex: orangeFlexSize() }"></div>
          <div class="yellow" :style="{ flex: yellowFlexSize() }"></div>
          <div class="green" :style="{ flex: greenFlexSize() }"></div>
          <div
            class="needle"
            v-bind:class="{ interactive: !isReadOnly }"
            :style="{
              left: `${100 * getPositionalCollateralRatio()}%`,
            }"
          >
            <div class="pointer"></div>
            <div :class="`data ${isReadOnly ? 'cursor-default' : ''}`">
              {{ getCollateralPercentage() }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getMouseDown from "../utils/getMouseDown";
import getAbsoluteElementPosition from "../utils/getAbsoluteElementPosition";

export default {
  name: "CollateralSlider",
  props: [
    "targetRatio",
    "minDisplayRatio",
    "maxDisplayRatio",
    "liquidateRatio",
    "ceaseRewardsRatio",
    "mintingRatio",
    "onChange",
    "isReadOnly",
    "minSliderRatio",
    "maxSliderRatio",
  ],
  data() {
    return {
      ratio: 0,
    };
  },
  watch: {
    targetRatio: {
      immediate: true,
      handler(newValue) {
        this.ratio = newValue;
      },
    },
  },
  mounted() {
    this.registerDragRatioListener();
  },
  beforeDestroy() {
    this.unregisterDragRatioListener();
  },
  methods: {
    getRelativeVisibleRatio(value) {
      return value / (this.maxDisplayRatio - this.minDisplayRatio);
    },
    redFlexSize() {
      return this.getRelativeVisibleRatio(
        this.liquidateRatio - this.minDisplayRatio
      );
    },
    orangeFlexSize() {
      return this.getRelativeVisibleRatio(
        this.ceaseRewardsRatio - this.liquidateRatio
      );
    },
    yellowFlexSize() {
      return this.getRelativeVisibleRatio(
        this.mintingRatio - this.ceaseRewardsRatio
      );
    },
    greenFlexSize() {
      return this.getRelativeVisibleRatio(
        this.maxDisplayRatio - this.mintingRatio
      );
    },
    /**
     * Returns the collateral ratio to be used in screen-space for
     * positioning the CR slider.
     */
    getPositionalCollateralRatio() {
      let value = this.ratio || 0;
      // Clamp value.
      if (value > this.maxDisplayRatio) value = this.maxDisplayRatio;
      if (value < this.minDisplayRatio) value = this.minDisplayRatio;
      return this.getRelativeVisibleRatio(value - this.minDisplayRatio);
    },
    /**
     * Returns a parsed value for the CR to be presented to the user.
     */
    getCollateralPercentage() {
      const value = Math.floor(this.ratio * 100);
      if (Number.isNaN(value) || value === 0) return "";
      if (Number.isFinite(value) && !Number.isNaN(value)) return `${value}%`;
      return value;
    },
    /**
     * Calculates a new collateral ratio based on mouse position.
     */
    updateRatio(event) {
      if (this.isReadOnly || !getMouseDown(0)) return;

      const bar = this.$refs["ratio-bar"];
      const barLeftPos = getAbsoluteElementPosition(bar).left;
      const x = (event.pageX - barLeftPos) / bar.offsetWidth;
      this.ratio =
        x * (this.maxDisplayRatio - this.minDisplayRatio) +
        this.minDisplayRatio;

      if (this.ratio > this.maxSliderRatio) this.ratio = this.maxSliderRatio;

      if (this.ratio < this.minSliderRatio) this.ratio = this.minSliderRatio;

      if (typeof this.onChange === "function") this.onChange(this.ratio);
    },
    registerDragRatioListener() {
      const wrapper = this.$refs["ratio-wrapper"];
      const bar = this.$refs["ratio-bar"];

      // Set timeout until wrapper and bar are in the DOM.
      if (wrapper == null && bar == null)
        return setTimeout(this.registerDragRatioListener.bind(this), 250);

      wrapper.addEventListener("mousemove", this.updateRatio.bind(this));
      wrapper.addEventListener("click", this.updateRatio.bind(this));
    },
    unregisterDragRatioListener() {
      const wrapper = this.$refs["ratio-wrapper"];
      wrapper?.removeEventListener("mousemove", this.updateRatio.bind(this));
      wrapper?.removeEventListener("click", this.updateRatio.bind(this));
    },
  },
};
</script>

<style lang="scss" scoped>
@use "../assets/styles/handle.fi" as handle;

.collateral-ratio {
  padding: 1.752rem 0 10px 0;

  .bar {
    position: relative;
    display: flex;
    height: 1.25rem;

    .red,
    .orange,
    .yellow,
    .green {
      height: 100%;
      flex: 1; // Set via JavaScript.
    }

    .red {
      background-color: handle.$error-color;
    }

    .orange {
      background-color: handle.$orange;
    }

    .yellow {
      background-color: handle.$warning-color;
    }

    .green {
      background-color: handle.$green;
    }

    .needle {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: cyan;
      user-select: none;

      &.interactive {
        cursor: pointer;
      }

      .pointer {
        bottom: -8px;
        top: -8px;
        position: absolute;
        left: -4px;
        width: 4px;
        border: 2px solid handle.$green;
        box-shadow: 0 0 2px 2px rgb(0 0 0 / 15%);
        background-color: rgb(0 0 0 / 15%);
      }

      .data {
        position: absolute;
        top: -35px;
        width: 80px;
        left: -40px; // 7px accounting for percentage character.
        text-align: center;
        color: handle.$green;
        font-weight: 100;
        text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
      }
    }
  }
}
</style>
