import Token from "@/types/Token";

export const SUPPORTED_FX_TOKENS = [
  Token.fxAUD,
  Token.fxEUR,
  Token.fxPHP,
  Token.fxUSD
  //Token.fxCNY,
  //Token.fxCHF,
  //Token.fxKRW
];
