<template>
  <div class="uk-margin">
    <label
      v-if="!hideLabel"
      class="uk-form-label hfi-form-label-width-medium"
      :for="id === '' ? 'selectFxToken' : id"
    >
      {{ label ? label : "fxToken" }}
    </label>
    <div class="uk-form-controls">
      <Select
        :id="id === '' ? 'selectFxToken' : id"
        :class="boundaryClass"
        :boundaryClass="boundaryClass"
        selectClasses="uk-width-expand"
        inputClasses="uk-text-left"
        :options="options || supportedTokens"
        :icons="[
          'fxAUDLogo.png',
          'fxPHPLogo.png',
          'fxCNYLogo.png',
          'fxEURLogo.png',
          'fxJPYLogo.png',
          'fxKRWLogo.png',
          'fxSGDLogo.png',
          'fxGBPLogo.png',
          'fxUSDLogo.png',
          'fxCHFLogo.png',
        ]"
        :value="value ? value : Token.fxAUD"
        @change="setFxToken"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import Select from "@/components/Select";
import { SUPPORTED_FX_TOKENS } from "@/utils/constants/supportedTokens";
import Token from "@/types/Token";

export default {
  name: "SelectFxToken",
  comments: [Select],
  props: {
    id: { type: String, default: "" },
    boundaryClass: { type: String, default: "select-fxToken-boundary" },
    value: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: "" },
    hideLabel: { type: Boolean, default: false },
    options: { type: Array },
  },
  data() {
    return {
      Token,
    };
  },
  computed: {
    supportedTokens() {
      return SUPPORTED_FX_TOKENS;
    },
  },
  methods: {
    setFxToken(value) {
      this.$emit("change", value);
    },
  },
};
</script>
