<template>
  <div class="borrow">
    <h2 :class="`${modal ? 'uk-h4 hfi-modal-title' : 'uk-h2'}`">borrow</h2>
    <form
      novalidate
      autocomplete="off"
      class="uk-form-width-large uk-margin-small-top"
    >
      <fieldset class="uk-fieldset uk-width-1-1">
        <SelectFxToken
          id="mintFxToken"
          class="mintFxToken-boundary"
          boundaryClass="mintFxToken-boundary"
          @change="(selection) => setFxToken(selection)"
          :disabled="
            wrongNetwork || !account || processingTransaction || loadingData
          "
          :value="token"
        />

        <div class="uk-margin">
          <label
            class="uk-form-label uk-flex uk-width-expand uk-flex-between"
            for="mintAmount"
          >
            <span>amount</span>
            <span>
              <span>
                bal: {{ balance ? formatEther(balance) : "0.00"
                }}{{ vault && ", " }}
              </span>
              <span v-if="vault && vault.debt">
                debt: {{ formatEther(vault.debt, 2) }} {{ token }}
              </span>
            </span>
          </label>

          <div class="uk-form-controls uk-position-relative uk-flex">
            <NumberInput
              class="uk-width-expand"
              id="mintAmount"
              type="number"
              placeholder="fxTokens to borrow"
              :value="amount"
              :alert="!hasEnoughBalance || !meetsMinimumFxMintAMount"
              :min="ethers.BigNumber.from(0)"
              @change="onSetAmount"
              :disabled="
                wrongNetwork || !account || processingTransaction || loadingData
              "
            />
            <button
              class="uk-button hfi-button hfi-input-button"
              @click="getMaxAmount"
              :disabled="
                wrongNetwork || !account || processingTransaction || loadingData
              "
            >
              max
            </button>
          </div>
        </div>

        <div class="uk-margin">
          <label
            class="uk-form-label uk-flex uk-flex-middle"
            for="collateralRatio"
          >
            vault collateral ratio
            <a
              class="hfi-info-button uk-margin-remove-top"
              uk-icon="icon: question; ratio: .75"
              :uk-tooltip="'title: ' + collateralTooltipText + '; pos: right'"
            />
          </label>

          <CollateralSlider
            id="collateralRatio"
            :target-ratio="parseFloat(formatEther(this.collateralRatio))"
            :min-display-ratio="
              parseFloat(ethers.utils.formatEther(this.mintingRatio())) * 0.8 -
              0.05
            "
            :liquidate-ratio="
              parseFloat(ethers.utils.formatEther(this.mintingRatio())) * 0.8
            "
            :cease-rewards-ratio="
              parseFloat(ethers.utils.formatEther(this.mintingRatio()))
            "
            :minting-ratio="
              parseFloat(ethers.utils.formatEther(this.mintingRatio()))
            "
            :max-display-ratio="
              parseFloat(ethers.utils.formatEther(this.mintingRatio())) + 2
            "
            :min-slider-ratio="
              parseFloat(ethers.utils.formatEther(this.mintingRatio())) + 1e-6
            "
            :on-change="onRatioSliderChange"
            :is-read-only="
              wrongNetwork || !account || processingTransaction || loadingData
            "
          />
        </div>

        <div v-if="isDepositingCollateral" class="uk-margin">
          <SelectCollateralToken
            :options="availableDepositCollaterals"
            v-on:change="(selection) => setCollateralToken(selection)"
            :disabled="wrongNetwork || !account || processingTransaction"
            :value="collateralToken"
          />

          <label
            class="uk-form-label uk-flex uk-width-expand uk-flex-between"
            for="collateralAmount"
          >
            <span>amount</span>
            <span
              >bal: {{ formatEther(normalisedCollateralBalance, 4) }}
              {{ collateralToken }}</span
            >
          </label>

          <div class="uk-form-controls uk-position-relative uk-flex">
            <NumberInput
              class="uk-width-expand"
              :alert="
                collateral &&
                (!this.isCollateralRatioValid || !hasEnoughBalance)
              "
              id="collateralAmount"
              type="number"
              step="0.0001"
              placeholder="tokens to deposit"
              :value="inputCollateral"
              :min="ethers.BigNumber.from(0)"
              @change="onSetSelectedCollateral"
              :disabled="
                amount <= 0 ||
                wrongNetwork ||
                !account ||
                processingTransaction ||
                !isDepositingCollateral
              "
            />
          </div>

          <div class="uk-margin">
            <label class="uk-form-label hfi-form-label-width-medium">
              price: {{ getCollateralRateFromTokenQuote() }}
              {{ this.token.substr(2) }} (<a
                class="hfi-link"
                target="_blank"
                :href="`https://data.chain.link/arbitrum/mainnet/fiat/${this.token
                  .substr(2)
                  .toLowerCase()}-usd`"
                >chainlink<i
                  class="uk-margin-small-left fal fa-external-link-square"
                ></i></a
              >)
            </label>
            <br />
            <label class="uk-form-label hfi-form-label-width-medium">
              value: {{ getCollateralDepositPriceFromTokenQuote() }}
              {{ this.token.substr(2) }}
            </label>
          </div>
        </div>

        <div class="uk-margin-small-top">
          <label
            class="uk-form-label hfi-form-label-width-medium"
            for="mintButton"
          ></label>

          <div class="uk-form-controls">
            <button
              id="mintButton"
              class="uk-button uk-button-primary uk-width-expand hfi-button"
              @click="mint"
              type="button"
              :disabled="!canTransact()"
            >
              {{ transactionButtonText() }}
            </button>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import { getDecimalsAmount } from "@/utils/utils";
import Token from "@/types/Token";
import { store } from "@/store";
import { provider, signer, addAssetToWallet } from "@/utils/wallet";
import { ethers } from "ethers";
import NumberInput from "@/components/NumberInput";
import { showNotification, formatPrice } from "@/utils/utils";
import sendTransaction from "@/contracts/utils/sendTransaction";
import CollateralSlider from "@/components/CollateralSlider";
import { addEventListener, removeEventListener } from "@/utils/event";
import Event from "@/types/Event";
import SelectFxToken from "@/components/SelectFxToken";
import SelectCollateralToken from "@/components/SelectCollateralToken";
import { closeAllNotifications } from "@/utils/utils";
import checkIsWeth from "@/contracts/utils/checkIsWeth";
import approve from "@/contracts/ERC20/approve";
import formatError from "@/utils/formatError";
import { getReferral } from "@/utils/url";
import CollateralTokens from "@/types/CollateralTokens";

export default {
  name: "BorrowComponent",
  props: {
    modal: { type: Boolean, default: false },
  },
  components: {
    SelectCollateralToken,
    SelectFxToken,
    NumberInput,
    CollateralSlider,
  },
  data() {
    return {
      mintFeePerMille: ethers.BigNumber.from(0),
      depositFeePerMille: ethers.BigNumber.from(0),
      minimumMintingAmountEth: ethers.BigNumber.from(0),
      amount: ethers.BigNumber.from(0),
      availableCollaterals: [],
      availableDepositCollaterals: [],
      /** Input collateral in the selected collateral's currency */
      inputCollateral: ethers.BigNumber.from(0),
      /** Internal collateral as Ether for calculations */
      collateral: ethers.BigNumber.from(0),
      collateralRatio: ethers.BigNumber.from(0),
      collateralBalance: ethers.BigNumber.from(0),
      freeCollateral: ethers.BigNumber.from(0),
      tokenDebt: ethers.BigNumber.from(0),
      collateralPrice: ethers.BigNumber.from(0),
      tokenRate: ethers.BigNumber.from(0),
      isDepositingCollateral: false,
      hasEnoughBalance: true,
      overrideGasPrice: ethers.BigNumber.from(0),
      overrideGasLimit: ethers.BigNumber.from(0),
      overrideEthDepositCollateral: ethers.BigNumber.from(0),
      token: store.state.token || Token.fxAUD,
      collateralToken: Token.ETH,
      collateralDetails: null,
      mintButtonText: "borrow",
      loadingRate: false,
      // Disables mint button and inputs.
      processingTransaction: false,
      loadingData: true,
      // Disabled mint button but not inputs (otherwise focus is lost).
      processingLogic: false,
      walletUpdateEventId: null,
      canTransact: () =>
        this.sdk != null &&
        this.account &&
        (!this.$route.meta?.networks ||
          this.$route.meta?.networks.includes(this.network)) &&
        !this.loadingRate &&
        !this.processingTransaction &&
        !this.processingLogic &&
        !this.loadingData &&
        this.hasEnoughBalance &&
        (!this.isDepositingCollateral || this.isCollateralRatioValid) &&
        this.amount.gt(0) &&
        (!this.isDepositingCollateral || this.collateral.gt(0)) &&
        this.meetsMinimumFxMintAMount,
      transactionButtonText: () => {
        if (!this.account) return "please connect wallet";

        if (this.wrongNetwork) return "please change network";

        if (!this.hasEnoughBalance) return "insufficient balance";

        if (!this.meetsMinimumFxMintAMount)
          return "minimum borrow amount not met";

        if (this.isDepositingCollateral && !this.isCollateralRatioValid)
          return (
            "collateral ratio must be at least " +
            (parseFloat(this.formatEther(this.mintingRatio())) * 100).toFixed(
              1
            ) +
            "%"
          );

        if (this.loadingData || this.sdk == null) return "loading...";

        if (this.processingLogic || this.processingTransaction)
          return "processing...";

        if (this.loadingRate) return "loading rate...";

        if (this.amount.eq(0) && this.isDepositingCollateral) {
          return "amount is zero";
        } else if (this.isDepositingCollateral) {
          return "deposit & borrow";
        }

        return this.mintButtonText;
      },
      Token,
      ethers,
      oneEth: ethers.utils.parseEther("1"),
      collateralTooltipText:
        "minimum borrowing collateralisation ratio for the fxToken vault ",
      formatEther: (value, digits = 2) =>
        (value
          ? parseFloat(ethers.utils.formatEther(value))
          : 0
        ).toLocaleString(undefined, this.digits(digits)),
      balance: null,
    };
  },
  computed: {
    account() {
      return store.state.account;
    },
    sdk() {
      return store.state.refHandleSDK.get();
    },
    stateToken() {
      return store.state.token;
    },
    /** The amount of collateral to deposit in the selected token */
    depositCollateral() {
      if (
        this.overrideEthDepositCollateral.gt(0) &&
        this.collateralToken === Token.ETH
      )
        return this.overrideEthDepositCollateral;
      return this.collateralPrice.gt(0)
        ? this.collateral
            // Convert from ETH to token price.
            .mul(ethers.constants.WeiPerEther)
            .div(this.collateralPrice)
            // Include deposit fee.
            .mul("1000")
            .div(ethers.BigNumber.from("1000").sub(this.depositFeePerMille))
        : ethers.constants.Zero;
    },
    isCollateralRatioValid() {
      return this.collateralRatio.gte(this.mintingRatio());
    },
    isCollateralAtMintingRatio() {
      return (
        this.isCollateralRatioValid &&
        this.collateralRatio.lte(
          this.mintingRatio().add(ethers.utils.parseEther("1"))
        )
      );
    },
    normalisedCollateralBalance() {
      return getDecimalsAmount(
        this.collateralBalance,
        this.collateralDecimals,
        18
      );
    },
    collateralDecimals() {
      if (this.vault == null) return 18;
      return (
        this.vault.collateral.find(
          (x) => x.token.symbol === this.collateralToken
        )?.token.decimals ?? 18
      );
    },
    vault() {
      return this.sdk?.vaults.find((x) => x.token.symbol === this.token);
    },
    minimumFxMintAMount() {
      if (this.tokenRate.eq(0)) return ethers.constants.Zero;
      const fxAmount = this.minimumMintingAmountEth
        .mul(ethers.constants.WeiPerEther)
        .div(this.tokenRate);
      // Round up to the nearest 100.
      const roundBy = ethers.utils.parseEther("100");
      return fxAmount.add(roundBy).sub(1).div(roundBy).mul(roundBy);
    },
    meetsMinimumFxMintAMount() {
      // If input amount is zero, return true as this is handled
      // separately.
      if (this.amount.eq(0)) return true;
      const current = this.tokenDebt.add(this.amount);
      return current.gte(this.minimumFxMintAMount);
    },
    network() {
      return store.state.network;
    },
    wrongNetwork() {
      return (
        this.$route.meta?.networks &&
        !this.$route.meta?.networks.includes(this.network)
      );
    },
  },
  watch: {
    collateral: {
      immediate: true,
      async handler() {
        await this.checkEthRequiredAgainstBalance();
      },
    },
    async sdk() {
      // Load data whenever the SDK initialises.
      if (this.loaded || !this.sdk) return;
      await this.loadData();
    },
    stateToken() {
      this.setFxToken(store.state.token);
    },
  },
  beforeUpdate() {
    this.mintButtonText = store.state.account
      ? "borrow"
      : "please connect wallet";
  },
  beforeMount() {
    this.mintButtonText = store.state.account
      ? "borrow"
      : "please connect wallet";
  },
  async mounted() {
    await this.initialiseState();
  },
  beforeDestroy() {
    removeEventListener(Event.WalletUpdate, this.walletUpdateEventId);
  },
  methods: {
    mintingRatio() {
      if (!this.sdk || this.vault == null || this.collateralDetails == null)
        return ethers.BigNumber.from("0");

      const minRatioNotAccountingForNewCollateral = this.vault.ratios.minting.gt(
        0
      )
        ? this.vault.ratios.minting
        : this.collateralDetails.mintCollateralRatio.mul(this.oneEth).div(100);

      // Depositing new collateral may change the vault's minimum ratio.
      // Get new minimum ratio from collateral deposit.
      const currentCollateralAsEth = this.vault.collateralAsEth;
      const newCollateralAsEth = currentCollateralAsEth.add(this.collateral);
      if (newCollateralAsEth.eq(0))
        return minRatioNotAccountingForNewCollateral;
      const oldShare = currentCollateralAsEth
        .mul(this.oneEth)
        .div(newCollateralAsEth);
      const collateralCr = this.sdk.protocol
        .getCollateralTokenBySymbol(
          this.collateralToken === Token.ETH ? Token.WETH : this.collateralToken
        )
        .mintCollateralRatio.mul(this.oneEth)
        .div("100");
      return oldShare
        .mul(minRatioNotAccountingForNewCollateral)
        .div(this.oneEth)
        .add(this.oneEth.sub(oldShare).mul(collateralCr).div(this.oneEth));
    },
    clearMessages() {
      this.successMessage = "";
      this.warningMessage = "";
    },
    initialiseState: async function () {
      this.processingTransaction = true;
      removeEventListener(Event.WalletUpdate, this.walletUpdateEventId);
      this.walletUpdateEventId = addEventListener(
        Event.WalletUpdate,
        this.resetState.bind(this)
      );
      await this.resetState();
      this.processingTransaction = false;
    },
    clearForm: function () {
      this.amount = ethers.BigNumber.from(0);
      this.collateral = ethers.BigNumber.from(0);
      this.collateralRatio = ethers.BigNumber.from(0);
      this.isDepositingCollateral = false;
    },
    resetState: async function () {
      this.loadingData = true;
      this.clearMessages();
      this.clearForm();

      await this.setUseGasEstimate(false);
      await this.loadData();
      this.loadingData = false;
    },
    loadData: async function () {
      if (!this.account || !this.sdk) return;
      this.minimumMintingAmountEth = await this.sdk.contracts.comptroller.minimumMintingAmount();
      this.processingTransaction = true;
      await Promise.all([
        this.setFxToken(this.token),
        this.setCollateralToken(this.collateralToken),
        this.getAvailableCollaterals(),
      ]);
      this.mintFeePerMille = await this.sdk.contracts.handle.mintFeePerMille();
      this.depositFeePerMille = await this.sdk.contracts.handle.depositFeePerMille();

      this.availableDepositCollaterals = [Token.ETH];
      const availableDepositCollaterals = this.availableCollaterals.filter(
        (c) => c !== Token.FOREX
      );
      this.availableDepositCollaterals = [
        ...this.availableDepositCollaterals,
        ...availableDepositCollaterals,
      ];

      this.processingTransaction = false;
    },

    async getAvailableCollaterals() {
      this.availableCollaterals = [];

      for (let collateral of CollateralTokens) {
        const collateralAddress = this.sdk.contracts[collateral].address;
        if (!collateralAddress || collateralAddress.length !== 42) continue;

        this.availableCollaterals.push(collateral);
      }
    },
    getCollateralBalance: async function () {
      if (this.collateralToken === Token.ETH) return await signer.getBalance();
      return await this.sdk.contracts[this.collateralToken].balanceOf(
        this.account
      );
    },
    checkEthRequiredAgainstBalance: async function () {
      if (!this.collateralDecimals) return;
      // Collateral balance is in native ERC20 value, convert to ETH with adjusted decimals.
      const normalisedDecimalsBalance = getDecimalsAmount(
        this.collateralBalance,
        this.collateralDecimals,
        18
      );
      this.hasEnoughBalance = normalisedDecimalsBalance
        .mul(this.collateralPrice)
        .div(this.oneEth)
        .gte(this.collateral);
    },
    /**
     * Returns the amount of tokens the user can mint without depositing
     * new collateral, i.e. via their current vault's free collateral.
     */
    getFreeMintTokens: function () {
      if (this.vault.ratios.minting.eq(0)) return ethers.BigNumber.from(0);
      return this.mintAmountFromCollateralAndRatio(
        this.vault.freeCollateralAsEth,
        this.mintingRatio()
      );
    },
    /**
     * Calculates the maximum amount of tokens possible for minting.
     */
    getMaxAmount: async function (event) {
      event.preventDefault();
      this.processingTransaction = true;
      if (!this.isDepositingCollateral) {
        await this.getMaxAmountForVaultCollateral();
        if (this.amount > 0) {
          this.processingTransaction = false;
          return;
        }
      }

      // Fetching max amount for deposit.
      let maxCollateral;
      if (this.collateralRatio.eq(0))
        this.collateralRatio = this.mintingRatio();
      if (this.collateralToken === Token.ETH) {
        maxCollateral = await this.getMaxAmountForEthCollateralDeposit();
      } else {
        const erc20Balance = await this.sdk.contracts[
          this.collateralToken
        ].balanceOf(this.account);
        const collateralRate = this.sdk.protocol.getCollateralTokenBySymbol(
          this.collateralToken === Token.ETH ? Token.WETH : this.collateralToken
        ).rate;
        maxCollateral = erc20Balance.mul(collateralRate).div(this.oneEth);
      }
      this.collateral = maxCollateral;
      this.collateralRatio = this.mintingRatio();
      this.processingTransaction = false;
      this.amount = this.getMaxAmountForCollateral(this.collateral);
      this.inputCollateral = this.depositCollateral;
    },
    getMaxAmountForVaultCollateral: async function () {
      this.amount = this.getFreeMintTokens();
      await this.onSetAmount();
    },
    /**
     * Updates the fxTokens to be the maximum purchasable amount of tokens,
     * accounting for gas value. This fetches the user balance and requests
     * a getRate with `gasEstimate = true`.
     */
    getMaxAmountForEthCollateralDeposit: async function () {
      this.isDepositingCollateral = true;
      const balance = await signer.getBalance();
      // Calculate max amount before gas.
      let gasLimit = ethers.BigNumber.from("500000");
      try {
        const maxAmount = this.getMaxAmountForCollateral(balance);
        gasLimit = await this.getMintWithEthGasUnitsEstimate(
          balance,
          maxAmount
        );
      } catch (error) {
        console.error("Gas estimation transaction reverted", error);
      }

      await this.setUseGasEstimate(gasLimit);
      const gasCost = this.overrideGasPrice.mul(this.overrideGasLimit);
      // Set overrideDepositCollateral to be used in the next WETH transaction,
      // unless one of the inputs is updated before sending the transaction.
      const maxAmount = balance.sub(gasCost);
      if (maxAmount.gt(0)) this.overrideEthDepositCollateral = maxAmount;
      return this.overrideEthDepositCollateral;
    },
    getMaxAmountForCollateral: function (collateral) {
      return this.mintAmountFromCollateralAndRatio(
        collateral,
        this.collateralRatio
      );
    },
    getMinCollateralForAmount: function (amount) {
      return this.mintCollateralFromAmountAndRatio(amount, this.mintingRatio());
    },
    getMintWithEthGasUnitsEstimate: async function (depositAmount, mintAmount) {
      const tx = await this.vault.mintWithEth(mintAmount, depositAmount, true);
      return await provider.estimateGas(tx);
    },
    /**
     * @param {ethers.BigNumber} collateral
     * @param {ethers.BigNumber} ratio 18-decimal precision ratio
     */
    mintAmountFromCollateralAndRatio(collateral, ratio) {
      if (this.tokenRate.eq(0) || ratio.eq(0)) return ethers.constants.Zero;
      let amount = collateral
        .mul(this.oneEth)
        .mul(this.oneEth)
        .div(ratio)
        .div(this.tokenRate);
      // Get amount without fee.
      const withoutFee = amount
        .mul("1000")
        .div(this.mintFeePerMille.add("1000"));
      const feePrice = amount.sub(withoutFee).mul(ratio).div(this.oneEth);
      amount = amount.sub(feePrice);
      // Apply tolerance of 0.05% due to accrued interest since page load.
      amount = amount.mul("9995").div("10000");
      return amount;
    },
    /**
     * @param {ethers.BigNumber} amount
     * @param {ethers.BigNumber} ratio 18-decimal precision ratio
     */
    mintCollateralFromAmountAndRatio(amount, ratio) {
      // Include fee.
      amount = amount.add(amount.mul(this.mintFeePerMille).div("1000"));
      // Apply tolerance of 0.05% due to accrued interest since page load.
      amount = amount.mul("10005").div("10000");
      return amount
        .mul(this.tokenRate)
        .mul(ratio)
        .div(this.oneEth)
        .div(this.oneEth);
    },
    /**
     * Gets the price rate for the current token.
     */
    getRate: async function () {
      this.loadingRate = true;
      try {
        this.tokenRate = this.sdk.protocol.getFxTokenBySymbol(this.token).rate;
      } catch (error) {
        console.error(error);
        if (error)
          showNotification("error", (await formatError(error)).toLowerCase());
      }
      this.loadingRate = false;
    },
    async mint() {
      this.processingTransaction = true;
      try {
        const tx = await this.getTx(true);

        await sendTransaction(
          tx,
          `follow wallet instructions to confirm your borrow of ${this.formatEther(
            this.amount
          )} ${this.token}`,
          undefined,
          async (_t, etherscanMessage) => {
            return (
              `${this.formatEther(this.amount)} ${
                this.token
              } borrowed successfully. your new balance is ${this.formatEther(
                this.vault.debt
              )} ${this.token}. ` + etherscanMessage
            );
          }
        );

        const tokenDetails = this.sdk.protocol.fxTokens.find(
          (token) => token.symbol === this.token
        );

        const tokenToAdd = {
          symbol: this.token,
          address: tokenDetails.address,
          decimals: tokenDetails.decimals,
          image: `https://app.handle.fi/${this.token}Logo.png`,
        };

        addAssetToWallet(tokenToAdd);
      } catch (error) {
        console.log(error);
        closeAllNotifications();
        if (error)
          showNotification("error", (await formatError(error)).toLowerCase());
      } finally {
        await this.resetState();
        this.processingTransaction = false;
      }
    },

    getTx: async function (returnTxData = false) {
      if (!this.isDepositingCollateral)
        return await this.vault.mintWithoutCollateral(
          this.amount,
          returnTxData,
          null,
          null,
          null,
          getReferral()
        );
      if (this.isDepositingCollateral && this.collateralToken === Token.ETH) {
        return await this.vault.mintWithEth(
          this.amount,
          this.depositCollateral,
          returnTxData,
          this.overrideGasLimit.gt(0) ? this.overrideGasLimit : null,
          this.overrideGasPrice.gt(0) ? this.overrideGasPrice : null,
          null,
          getReferral()
        );
      }

      // Depositing ERC20 as collateral.
      await approve(
        signer,
        this.sdk.contracts.comptroller.address,
        this.collateralToken,
        this.depositCollateral
      );

      // Convert the ERC20 from 18 decimals to the actual decimal used by the token.
      const depositCollateralNormalised = getDecimalsAmount(
        this.depositCollateral,
        18,
        this.collateralDecimals
      );

      return await this.vault.mint(
        this.amount,
        this.collateralToken,
        depositCollateralNormalised,
        returnTxData,
        null,
        null,
        null,
        getReferral()
      );
    },
    /**
     * Sets the state values for override gas limit and estimated price.
     */
    setUseGasEstimate: async function (gasLimit) {
      if (
        this.depositCollateral !== Token.ETH ||
        !ethers.BigNumber.isBigNumber(gasLimit)
      ) {
        this.overrideGasLimit = ethers.BigNumber.from(0);
        this.overrideGasPrice = ethers.BigNumber.from(0);
        return;
      }
      this.overrideGasLimit = gasLimit;
      this.overrideGasPrice = await provider.getGasPrice();
    },
    onSetCollateral: async function (value) {
      if (typeof value === "string") {
        this.collateral = ethers.utils.parseEther(value.toString());
      } else if (ethers.BigNumber.isBigNumber(value)) {
        this.collateral = value;
      }
      this.overrideEthDepositCollateral = ethers.constants.Zero;
      this.processingLogic = true;
      await this.recalculateCollateralRatio();
      this.processingLogic = false;
    },
    /**
     * Used for setting the collateral amount according to the currently
     * selected collateral type, which is converted to Ether for internal use.
     */
    onSetSelectedCollateral: async function (value) {
      if (value == null) return;
      this.inputCollateral = value;
      const collateralAsEther = this.inputCollateral
        .mul(this.collateralPrice)
        .div(this.oneEth);
      await this.onSetCollateral(collateralAsEther);
    },
    recalculateCollateralRatio: async function () {
      if (this.amount.lte("0")) {
        this.collateralRatio = this.vault.ratios.current;
        return;
      }
      const collateral = this.vault.collateralAsEth.add(this.collateral);
      const amount = this.tokenDebt.add(this.amount);
      const isZero = amount.eq(0) || this.tokenRate.eq(0);
      this.collateralRatio = !isZero
        ? collateral
            .mul(this.oneEth)
            .mul(this.oneEth)
            .div(amount.mul(this.tokenRate))
        : ethers.constants.Zero;
      if (isNaN(this.collateralRatio))
        this.collateralRatio = this.mintingRatio();
    },
    setFxToken: async function (option) {
      if (!this.sdk || !this.vault) return;
      this.token = option;
      this.loadingData = true;
      this.balance = await this.sdk.contracts[this.token].balanceOf(
        this.account
      );
      this.collateralRatio = this.vault.ratios.current || ethers.constants.Zero;
      await this.getRate();
      this.tokenDebt = this.vault.debt;
      await this.setCollateralToMintingCR();
      this.loadingData = false;
    },
    setCollateralToken: async function (option) {
      this.loadingData = true;
      this.collateralToken = option;
      this.collateralBalance = await this.getCollateralBalance();
      this.collateralDetails = this.sdk.protocol.getCollateralTokenBySymbol(
        this.collateralToken === Token.ETH ? Token.WETH : this.collateralToken
      );
      try {
        if (
          this.collateralToken === Token.ETH ||
          (await checkIsWeth(this.collateralToken))
        ) {
          this.collateralPrice = this.oneEth;
        } else {
          this.collateralPrice = this.sdk.protocol.getCollateralTokenBySymbol(
            this.collateralToken
          ).rate;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingData = false;
        await this.setCollateralToMintingCR();
      }
    },
    /**
     * @param {BigNumber} value
     */
    onSetAmount: async function (value) {
      if (ethers.BigNumber.isBigNumber(value) && value.eq(this.amount)) {
        return;
      } else if (value != null) {
        this.amount = value;
      }
      this.overrideEthDepositCollateral = ethers.constants.Zero;
      this.processingLogic = true;
      const freeCollateral = this.vault.freeCollateralAsEth;
      const requiredCollateral = this.getMinCollateralForAmount(this.amount);
      this.isDepositingCollateral = requiredCollateral.gt(freeCollateral);
      if (!this.isDepositingCollateral) {
        this.collateral = ethers.BigNumber.from(0);
        await this.recalculateCollateralRatio();
      } else if (this.amount.gt(0)) {
        // Load minimum minting collateral.
        await this.setCollateralToMintingCR();
      }
      this.inputCollateral = this.depositCollateral;
      this.processingLogic = false;
    },
    onRatioSliderChange: async function (newValue) {
      if (!ethers.BigNumber.isBigNumber(newValue))
        newValue = ethers.utils.parseEther(newValue.toString());

      this.collateralRatio = newValue;
      this.overrideEthDepositCollateral = ethers.constants.Zero;

      const freeCollateral = this.vault.freeCollateralAsEth;
      const requiredCollateral = this.getMinCollateralForAmount(this.amount);
      const isHigherCr = this.collateralRatio.gt(this.vault.ratios.current);

      const wasDepositing = this.isDepositingCollateral;
      this.isDepositingCollateral =
        requiredCollateral.gt(freeCollateral) || isHigherCr;

      if (isHigherCr && (!this.isDepositingCollateral || !wasDepositing))
        this.amount = ethers.BigNumber.from(0);

      if (this.isDepositingCollateral) {
        await this.recalculateCollateral();
      } else {
        // Calculate amount given adjusted CR using the new collateral ratio.
        this.amount = this.getMaxAmountForCollateral(
          this.vault.collateralAsEth
        ).sub(this.tokenDebt);
        if (this.amount.lt(0)) this.amount = ethers.BigNumber.from(0);
        await this.onSetAmount();
      }
    },
    /**
     * Calculates collateral from current amount and ratio values.
     */
    recalculateCollateral: async function () {
      const collateral = this.calculateNewVaultCollateralFromRatio(
        this.collateralRatio
      );
      this.collateral = collateral.sub(this.vault.collateralAsEth);

      // Negative collateral deposit assumes using existing vault collateral.
      // Value may be negative when dragging the CR slider under existing vault CR.
      if (this.collateral < 0) {
        this.isDepositingCollateral = false;
        this.collateral = ethers.BigNumber.from(0);
      }

      this.inputCollateral = this.depositCollateral;
    },
    calculateNewVaultCollateralFromRatio: function (ratio) {
      const newAmount = this.tokenDebt.add(this.amount);
      return this.mintCollateralFromAmountAndRatio(newAmount, ratio);
    },
    setCollateralToMintingCR: async function () {
      if (this.vault == null) return;
      if (this.amount.eq(0)) return;
      const collateral = this.calculateNewVaultCollateralFromRatio(
        this.mintingRatio()
      );
      this.collateral = collateral.sub(this.vault.collateralAsEth);
      await this.onRatioSliderChange(this.mintingRatio());
      // Make sure a valid CR is set. Numerical approach for simplicity.
      // Do this in a loop while CR is invalid (lower bound and then upper bound)
      let isAboveRatio =
        this.isCollateralRatioValid && !this.isCollateralAtMintingRatio;
      const minCr = this.mintingRatio();
      const step = minCr.mul(2).div(1000); // 0.2% increments/decrements
      let steps = 0;
      do {
        const value = !isAboveRatio
          ? minCr.add(step.mul(steps))
          : minCr.sub(step.mul(steps));
        await this.onRatioSliderChange(value);
        steps++;
      } while (!this.isCollateralAtMintingRatio && steps < 50);
    },
    getCollateralRateFromTokenQuote: function () {
      const collateralPriceInToken = this.collateralPrice / this.tokenRate;
      return formatPrice(collateralPriceInToken);
    },
    getCollateralDepositPriceFromTokenQuote: function () {
      const depositPriceInToken = this.collateral / this.tokenRate;
      return formatPrice(depositPriceInToken);
    },
    digits(minDigits, maxDigits = minDigits) {
      return {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      };
    },
  },
};
</script>
