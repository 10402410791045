import { ethers } from "ethers";
import parseFxToken from "../utils/parseFxToken";
import ERC20 from "../../../build/contracts/interfaces/IERC20.sol/IERC20.json";

/**
 * Approves an ERC20 for spending by spender.
 * @param {ethers.Signer} signer
 * @param {String} spender
 * @param {String} fxToken
 * @param {String | Number | BigNumber} amount
 * @returns {Promise<void>}
 */
export default async (signer, spender, fxToken, amount) => {
  fxToken = parseFxToken(fxToken);
  const erc20 = new ethers.Contract(fxToken, ERC20.abi, signer);
  // Get only missing approval.
  if (!ethers.BigNumber.isBigNumber(amount))
    amount = ethers.BigNumber.from(amount.toString());
  const delta = amount.sub(
    await erc20.allowance(await signer.getAddress(), spender)
  );
  if (delta.lte(0)) return;
  await (await erc20.approve(spender, amount.toString())).wait(1);
};
