import getContractAddresses from "./getContractAddresses";
import parseFxToken from "../utils/parseFxToken";

/**
 * Returns the current quote for x amount of tokens
 * @param {String} token the token address to quote for
 * @returns true if the token is WETH, false otherwise
 */
export default async (token) => {
  const addresses = await getContractAddresses();
  if (parseFxToken(token) === addresses.thirdPartyContracts.WETH) {
    return true;
  }
  return false;
};
